// extracted by mini-css-extract-plugin
export var root = "payment-modal-module--root--3g71_";
export var paymentVeil = "payment-modal-module--payment-veil--zqPeE";
export var creditCardWrapper = "payment-modal-module--credit-card-wrapper--1KTeD";
export var ready = "payment-modal-module--ready--3TtKB";
export var initializing = "payment-modal-module--initializing--2ojJa";
export var progressWrapper = "payment-modal-module--progress-wrapper--3_wlq";
export var progress = "payment-modal-module--progress--1bH7m";
export var spacer = "payment-modal-module--spacer--3rAfW";
export var stripeBadge = "payment-modal-module--stripe-badge--38qn5";
export var svg = "payment-modal-module--svg--3E26v";