// extracted by mini-css-extract-plugin
export var root = "index-module--root---qPQS";
export var issueLinkInput = "index-module--issue-link-input--1dnQ6";
export var cardContent = "index-module--card-content--3dea_";
export var textFieldContainer = "index-module--text-field-container--2EgRu";
export var textField = "index-module--text-field--tr50E";
export var body = "index-module--body--3tU5W";
export var spinner = "index-module--spinner--39DBE";
export var active = "index-module--active--AuPfH";
export var issueRoot = "index-module--issue-root--lVz5A";
export var issueBox = "index-module--issue-box--3G28U";
export var issue = "index-module--issue--3f1hi";
export var repoTitle = "index-module--repo-title--3lXVr";
export var issueTitle = "index-module--issue-title--22ChH";
export var markdown = "index-module--markdown--3Vc4G";
export var issueNumber = "index-module--issue-number--1vszH";
export var bountyActivity = "index-module--bounty-activity--3gKdZ";
export var dateMark = "index-module--date-mark--3uHiE";
export var date = "index-module--date--1Zax8";
export var time = "index-module--time--3ptaE";
export var timelineContent = "index-module--timeline-content--CnLev";
export var title = "index-module--title--A8u-o";
export var subtext = "index-module--subtext--X_L4j";
export var bounties = "index-module--bounties--3gvFK";
export var bountyAmount = "index-module--bounty-amount--1X0Eu";
export var labelContainer = "index-module--label-container--3llJ5";
export var amountRaised = "index-module--amount-raised--310Ek";
export var amountOfSponsors = "index-module--amount-of-sponsors--2IguF";
export var buttonContainer = "index-module--button-container--142c3";
export var claimButton = "index-module--claim-button--ofOW3";
export var disabled = "index-module--disabled--2V38n";
export var addButton = "index-module--add-button--1Pfs_";
export var consent = "index-module--consent--bYYhv";
export var label = "index-module--label--18y3R";
export var consentCheckbox = "index-module--consent-checkbox--1XblI";
export var pullRequest = "index-module--pull-request---3WBf";
export var number = "index-module--number--3Eks8";